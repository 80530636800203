import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import "../about-us/AboutUs.scss";
import * as PR from "../../prime-modules/index";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { useTranslation } from "react-i18next";
import SubHeader from "../../components/SubHeader";
import { messageCatalogActions } from "../../store/messageCatalog";
import { getAssetsData } from "../../services/api";
import { useToast } from "../../context/ToastContext";
import getAssets from "../../assets/data/brandingAssets.json"

const AboutUs = () => {
  const { aboutus, general, isBase64Image } = useToast();
  const dispatch = useDispatch();
  const { i18n } = useTranslation();
  useEffect(() => {
    const getResponse = (response) => {
      if (response.result === "SUCCESS") {
        dispatch(messageCatalogActions.storeAboutUs(response.data))
      } 
    }
    getAssetsData(i18n.language, 'aboutUs', getResponse)
  }, [i18n.language, dispatch])

  return (
     <HelmetProvider> 
      <Helmet>
          <meta charSet="utf-8" />
          <title>{`${general.GEN0001} | ${aboutus?.AB0001}`} </title>
      </Helmet>

      {/* Header Section */}
      <SubHeader
        header={aboutus.AB0001}
        subText={
          <>
            {aboutus.AB0002}
          </>
        }
        visible={true}
      />      

      <section className="about-blocks-section">
        <div className="custom-container">
          <div className="grid align-items-center custom-margin">
              <div className="col-12 lg:col-6 md:col-6">
                <PR.Image src={isBase64Image(aboutus.AB0003) ? `data:image/png;base64,${aboutus.AB0003}`: getAssets.about_us.unlock_world} className="about-img" alt="Unlock a World of Seamless Connectivity"/>
              </div>
              <div className="col-12 lg:col-6 md:col-6 right">
                <h2>{aboutus.AB0004}</h2>
                <p>{aboutus.AB0005}</p>
              </div>
          </div>
          <div className="grid align-items-center custom-margin column-reverse">
              <div className="col-12 lg:col-6 md:col-6 right">
                <h2>{aboutus.AB0006}</h2>
                <p>{aboutus.AB0007}</p>
              </div>
              <div className="col-12 lg:col-6 md:col-6">
                <PR.Image src={isBase64Image(aboutus.AB0008) ? `data:image/png;base64,${aboutus.AB0008}`: getAssets.about_us.instant_activation} className="about-img" alt="Instant Activation"/>
              </div>
          </div>
          <div className="grid align-items-center custom-margin">
              <div className="col-12 lg:col-6 md:col-6">
                <PR.Image src={isBase64Image(aboutus.AB0009) ? `data:image/png;base64,${aboutus.AB0009}`: getAssets.about_us.global_coverage} className="about-img" alt="Global Coverage"/>
              </div>
              <div className="col-12 lg:col-6 md:col-6 right">
                <h2>{aboutus.AB0010}</h2>
                <p>{aboutus.AB0011}</p>
              </div>
          </div>
          <div className="grid align-items-center custom-margin column-reverse">
              <div className="col-12 lg:col-6 md:col-6 right">
                <h2>{aboutus.AB0012}</h2>
                <p>{aboutus.AB0013}</p>
              </div>
              <div className="col-12 lg:col-6 md:col-6">
                <PR.Image src={isBase64Image(aboutus.AB0014) ? `data:image/png;base64,${aboutus.AB0014}`: getAssets.about_us.attractive_rates} className="about-img" alt="Attractive Rates"/>
              </div>
          </div>
        </div>
      </section>
    </HelmetProvider>
  );
};

export default AboutUs;