import { useMemo, useState } from "react";
import * as PR from '../../prime-modules/index';
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from 'yup';
import { changeUserPassword } from "../../services/api";
import { authActions } from "../../store/auth";
import { cartFilterActions } from "../../store/cart";
import { removeWhiteSpacesInPassword } from "../../utils/reuse";
import { useToast } from "../../context/ToastContext";
import { esimIccidActions } from "../../store/esim";

const ChangePassword = () => {
    const dispatch = useDispatch();
    const { showToast, validations, account } = useToast();
    const userData = useSelector(state => state.auth.sessionData);
    const affiliateTrackingData = useSelector(state => state.affiliateTracking.affiliateTrackingInfo);
    const headers = useMemo(() => {
        return {sessionid: userData.sessionId, afid: affiliateTrackingData};
    }, [userData.sessionId, affiliateTrackingData]);

    const [disableBtn, setDisableBtn] = useState(false);
    const initialValues = {
        password: '',
        newPassword: '',
        confirmPassword: ''
    };

    const validationSchema = () => {
        return Yup.object().shape({
            password: Yup.string().trim()
                .required("VLD0001"),
            newPassword: Yup.string().trim()
                .required("VLD0001")
                .min(8, "VLD0012")
                .max(20, "VLD0012")
                .matches(/\d/, "VLD0012")
                .matches(/[A-Za-z]/, "VLD0012")
                .strict(true),
            confirmPassword: Yup.string().trim()
                .required("VLD0013")
                .when("password", {
                    is: val => (val && val.length > 0 ? true : false),
                    then: Yup.string().oneOf(
                        [Yup.ref("password")],
                        "VLD0014"
                    )
                }),
        });
    };

    const handleSubmit = values => {
        setDisableBtn(true);
        const requestObj = {
            userId: userData.userId ?? 0,
            password: values.password,
            newPassword: values.newPassword
        }
        changeUserPassword(requestObj, headers, dispatch, response => {
            if (response.result === 'SUCCESS') {
                showToast('success', 'CHGPWD-05')
                setTimeout(() => {
                    dispatch(authActions.onLogout());
                    dispatch(esimIccidActions.reseteSIMS());
                    dispatch(cartFilterActions.removeRandomIdInPayment());
                }, [2000])
            } else {
                const error = response.error;
                showToast(error.severity, error.errorMsg);
            }
            setDisableBtn(false);
        })
    }

    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: validationSchema,
        onSubmit: handleSubmit
    })

    return ( 
        <div className="esim-box">
        <h2>{account.ACT0054}</h2>
        <form onSubmit={formik.handleSubmit} autoComplete={"false"}>
          <div className="grid mb-3">
            <div className="col-12 lg:col-6 md:col-6">
              <div className="flex flex-column gap-2">
                  <label>{account.ACT0055}</label>
                  <span className="p-input-icon-right">
                      <PR.Password  feedback={false}  name='password' placeholder={account.ACT0055} className="w-12" autoComplete="off" value={formik.values.password} onChange={e => removeWhiteSpacesInPassword(e, formik, "password")} onBlur={formik.handleBlur} toggleMask />
                  </span>
                  {formik.errors.password && formik.touched.password && <div className='error-message'>{validations[formik.errors.password]}</div>}
              </div>
            </div>
          </div>
          <div className="grid mb-3">
            <div className="col-12 lg:col-6 md:col-6">
              <div className="flex flex-column gap-2">
                  <label>{account.ACT0056}</label>
                  <span className="p-input-icon-right">
                      <PR.Password feedback={false} name='newPassword' placeholder={account.ACT0056} className="w-12" autoComplete="off" value={formik.values.newPassword} onChange={e => removeWhiteSpacesInPassword(e, formik, "newPassword")} onBlur={formik.handleBlur} toggleMask maxLength={20} />
                  </span>
                  {formik.errors.newPassword && formik.touched.newPassword && <div className='error-message'>{validations[formik.errors.newPassword]}</div>}
              </div>
            </div>
          </div>
          <div className="grid">
            <div className="col-12 lg:col-6 md:col-6">
              <div className="flex flex-column gap-2">
                  <label>{account.ACT0057}</label>
                  <span className="p-input-icon-right">
                      <PR.Password feedback={false} name='confirmPassword' placeholder={account.ACT0057} className="w-12" autoComplete="off" value={formik.values.confirmPassword} onChange={e => removeWhiteSpacesInPassword(e, formik, "confirmPassword")} onBlur={formik.handleBlur} toggleMask maxLength={20} />
                  </span>
                  {formik.errors.confirmPassword && formik.touched.confirmPassword && <div className='error-message'>{validations[formik.errors.confirmPassword]}</div>}
              </div>
            </div>
          </div>
          <div className="grid">
            <div className="col-12">
              <PR.Button label={account.ACT0058} className="update-button p-button-rounded" disabled={!formik.isValid || disableBtn} />
            </div>
          </div>
        </form>
    </div>
     );
}
 
export default ChangePassword;