import React from "react";
import "../terms-and-conditions/TermsAndConditions.scss";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import HeaderMenu from "../../components/layout/HeaderMenu";
import { Link } from "react-router-dom";
import { useToast } from "../../context/ToastContext";

const TermsAndConditions = () => {
    const { labels, general,  globalConfig} = useToast();
  return (
     <HelmetProvider> 
        <Helmet>
            <meta charSet="utf-8" />
            <title>{general.GEN0009} | {labels.LBL0178}</title>
        </Helmet>
    
    <section className="tc-pp-section">
        <div className="header">
            <header>
                <HeaderMenu />
            </header>
            <div className="fixed-height"></div>
        </div>
    </section>

    <section className="about-blocks-section tc-block">
        <div className="terms-and-conditions-section">
            <div className="mb-5">
                <h1 className="main-heading">{labels.LBL0178}</h1>
                <h2 className="sub-heading">{labels.LBL0323}</h2>
            </div>
            <p>eSimlinks shall be the company name or a Doing Business As 'dba' or a trade name of a parent company. In this Terms of Use and Services, SIM can mean either an eSIM or a physical Smart SIM or a combination of both - eSIM/Smart SIM</p>
            <ol className="sub-number-ol mt-4">
                <li>
                    <span className="heading">General</span>
                    <ol>
                        <li><span>eSimlinks eSIM/SIM and Smart SIM & Airtime/Credit Purchase, Usage and Airtime consumption.</span>
                            If th eSimlinks eSIM/SIM listed in this website and associated websites is defective or dead upon arrival, due to unforeseen circumstances resulting in the malfunctioning of the (eSimlinks eSIM/SIM, which is very unlikely in most circumstances; you may return the SIM Card within 30 days of receipt as long as the SIM is in the same condition in which it was received. Exception is that of eSimlinks Airtime/Credit Purchased. Return/Refund Policy of Airtime/Credit Purchased for use is indicated somewhere down this document.
                        </li>
                        <li>
                            <span>Cell Phone & Accessories - Cell Phone & Accessories:</span>
                            If any of eSimlinks Cell Phone & Accessories listed in this website and associated websites is defective or dead upon arrival due to unforeseen circumstances resulting in the malfunctioning of the product which is very unlikely in most circumstances; you may return the product within 30 days of receipt if the product is in the same condition in which it was received.
                        </li>
                        <li>
                            <span>Our Reserved Rights of inability to ship Unavailability of Bonus Cell Phone Accessories:</span>
                            Please, note that bonus products could be discontinued at any time without prior notice and once discontinued, we have no obligation whatsoever to refund any customer any money for the value of any or all the bonus products given out free with the order of the eSimlinks eSIM/SIM. Your eSimlinks eSIM/SIM may come with or without the bonus products. For refund policies, please see our website for details.
                        </li>
                        <li>
                            <span>eSimlinks eSIM/SIM Plans</span>
                            You agree to make use of the eSimlinks eSIM/SIM plan subscribed for with the recommended/compatible device(s) to effectively enjoy the eSimlinks eSIM/SIM plan. If the inaccessibility of any of eSimlinks eSIM/SIM plans subscribed for (during validity duration of the subscription) emanating from your end due to your inability to make use of the recommended compatible and/or specified phone handset or tablet or desktop, eSimlinks nor its parent organization or other divisions would be held responsible and there would be no refund of the subscription fee for that month or plan neither the initial set-up fee paid for the account. eSimlinks may not be held responsible if you were unable to access any of the eSimlinks eSIM/SIM plans after subscription payment in any of the coverage countries or you were unable to access or make use of the telephone numbers due to technical issues or problems emanating from our end. However, in such circumstances, you may be refunded the subscription fee, less any initial set-up fee.
                        </li>
                        <li>
                            <span>Payment Methods & KYC Procedures</span>
                            Payment Methods: We accept major credit cards (Amex, Visa, MasterCard, Discover, and JCB) and crypto currency (e.g., Bitcoin).
                            Know Your Customer (KYC) Procedure: <br/>
                            As part of our KYC procedures and as required by our Credit/Debit Card processor/acquiring bank for all credit/debit cards processed for first time customers order and subsequent order, you may be required by the payment gateway provider/processor fulfil all required 3D/2-Factor Authentication and Authorization for using your card to purchase eSimlinks eSIM/SIM online.
                        </li>
                    </ol>
                    <p></p>
                </li>
                <li>
                    <span className="heading">Services</span>
                    <ol>
                        <li> <span>General</span>
                        eSimlinks will only be responsible for marketing and providing voice transmission services including outgoing calls, incoming calls, and where applicable recharging of airtime credit to any eSimlinks eSIM/SIM. However, local carriers reserve the right to change their tariff rates and coverage areas/countries at any time without prior notice. They reserve the right to increase or decrease the amount of airtime included with each eSIM/SIM without prior notice. The local carriers also reserve the right to change, remove or add any of the services provided through the eSIMs/SIMs at any time without prior notice.
                        </li>
                        <li> <span>Advertisements</span>
                        While eSimlinks takes responsibility for its own adverts' contents display on this website and its associated website for adverts regarding its own products and services, other adverts' contents display by third party advertisers on eSimlinks website, and its associated websites are solely the responsibility of those third-party advertisers and adverts' contents display are governed by their own terms and conditions are indicated in their various websites. eSimlinks and its associated websites shall not be responsible for any misinformation or misrepresentation of such adverts' contents by third party advertisers displayed on its website and associated websites.
                        </li>
                    </ol>
                </li>
                <li>
                    <span className="heading">Warranty</span>
                    <p>eSimlinks makes No WARRANTY of any kind, expressed or implied, as to the coverage areas for GSM eSIMs/SIMs, any of its Cell Phones & Cell Phone Accessories/products, Global WiFi access and respective services provided by the local wireless carriers. eSimlinks reserves the right to refuse returns for items/products/services deemed to have been purchased for use and later return. Please, note that eSimlinks eSIM/SIM wireless usage in terms of making and receiving a call, voice quality and clarity may be interfered by the following factors but not limited to service outages, structures, weather conditions, phone used, traffic volume, network changes, roaming networks disruption thereby affecting SIM outgoing and/or incoming calls, technical limitations, and signal strength. This is also applicable to the Global WiFi access as well as the eSimlinks eSIM/SIM plans.<br/>
                    eSimlinks eSIM/SIM user agrees to use the SIM at his or her own risk and neither Phone System Global nor its parent, subsidiaries, affiliates, employees, or agents warrant that the service will be uninterrupted nor error free.<br/>
                    eSimlinks eSIM/SIM plans - Subscriber agrees to access the eSimlinks eSIM/SIM plans at his or her own risk and that neither eSimlinks or its parent company, subsidiaries, affiliates, employees, or agents warrant that the service will be uninterrupted or error free. <br/>
                    These services and products are provided as they are available without any warranties of any kind, either express or implied, including but not limited to warranties of title or implied warranties of merchantability or fitness for a particular purpose of otherwise, including publishing errors within its websites. eSimlinks and its affiliates, employees and agents specifically disclaim all warranties, including without limitation, warranties of title, merchantability, and fitness for a particular purpose and non-infringement of third-party rights.<br/>
                    In addition, eSimlinks does not make any warranty that a customer request will be processed in a timely manner including the time to process a request as such timely manner is subject to other external factors outside our control which include but not limited to: web based processing, cryptocurrencies or digital currencies payments, credit/debit cards and/or banking confirmation and processing periods, local/international mailing systems.</p>
                </li>
                <li>
                    <span className="heading">Terms and Conditions - Terms of use</span>
                    <p>Please, note that these terms and conditions of Use (otherwise known as “Legal Notice” apply to this eSimlinks website and all its associated websites. Note that eSimlinks and other subsidiaries and affiliate websites may have their own separate policies and procedures. <br/>
                    This site is accessible worldwide and by using this site and all other associated websites, the user agrees to these terms (Legal Notice). If you do not agree to these terms do not use this website and its associated websites. Do not use this site for other unlawful purposes.</p>
                </li>
                <li>
                    <span className="heading">Downloading of Materials from this website</span>
                    <p>You may download material displayed on the eSimlinks website for personal, non-commercial use only and may not transmit, use, reuse, distributor or modify any materials displayed on eSimlinks site and its associated websites. Use of eSimlinks website contents for any public or commercial purpose must request express written permission from eSimlinks prior to usage.</p>
                </li>
                <li>
                    <span className="heading">Fair Usage Policy</span>
                    <p>For all Unlimited Data Plans, eSimlinks implement a fair usage policy; after 1GB has been used at full speed, the data speed will run at 512 kbps. The Data speed resets every 24 hours depending on when the Data Plan was first activated and the first 1GB will run at full speed after each reset.</p>
                </li>
                <li>
                    <span className="heading">Prohibited Activities</span>
                    <p>You may not use any system that supports hacking or any unauthorized or illegal means to gain unauthorized access to any part or function of this Site listed in this website and its associated websites. You may not use any device or means or attempt to interfere with the proper working of this site. You may not copy, scan, or otherwise breach any security measures of this site. Impersonation of this site is totally not unlawful.</p>
                </li>
                <li>
                    <span className="heading">Pricing</span>
                    <p>Prices are subject to change without prior notice. Products are shipped according to availability of stocks.</p>
                </li>
                <li>
                    <span className="heading">Cancellation/Return/Refund Policy - See Cancellation/Returns/Refunds Policy.</span>
                    <p>In situations where you are unable to activate an eSIM or unable to start using data on an eSIM, a refund may be issued if we cannot resolve the issue for you. Please contact us on <Link href={`mailto:${globalConfig.supportEmail}`} target='_top' rel="noreferrer">{globalConfig.supportEmail}</Link> with details about your order. A refund will not be issued if you have started using data on an eSIM.</p>
                </li>
                <li>
                    <span className="heading">eSimlinks eSIM/SIM, Cell Phones and Cell Phones Accessories, Global WiFi access Limited Warranty</span>
                    <p>All eSimlinks eSIM/SIM and some Cell Phones & Accessories come with a limited warranty, which up to 1-year warranty. If, however, your eSimlinks eSIM/SIM is defective and is within one year after receipt, then please contact us at us <Link href={`mailto:${globalConfig.supportEmail}`} target='_top' rel="noreferrer">{globalConfig.supportEmail}</Link> for replacement. No cash value will be given for defective eSimlinks eSIM/SIM used within the warranty period, but a replacement of new SIM Card will be shipped to you directly or through eSimlinks distributor in your area through which you purchased the SIM. Shipping and Handling fee would be the responsibility of Phone System Global.</p>
                </li>
                <li>
                    <span className="heading">Pictorial Representation</span>
                    <p>Images/pictures on this website, its associated websites and/or catalogs may vary in sizes and formats as represented from the actual products.</p>
                </li>
                <li>
                    <span className="heading">Termination, Suspension of eSIM/SIM Accounts deemed Inactive</span>
                    <p className="mb-4">You agree that eSimlinks at its sole discretion may terminate your use of the Service or individual services provided or remove and discard any Content with such services. eSimlinks may suspend all or any of the Services or individual services for lack of use of the SIM. Please, note that in a scenario where eSimlinks terminates your eSIM/SIM account with respect of violation of the use of the SIM as stated in this website, and if after thorough investigation that the violation was not a fault of your direct use of the SIM, any remaining air time balance in your account that was purchased less than 30 days from the date of your SIM account termination will be refunded to you; however, any remaining airtime in your SIM account that was purchased more than 30 days from the date of termination of your SIM account is non-refundable.</p>
                    <p className="mb-4">eSIM/SIM Account Suspension - A SIM account may be suspended if the user is using the SIM for activities different from those permitted by law. Such eSIM/SIM account may be restored if we receive satisfactory answers that the user was not involved in the use of the SIM for such activities but perhaps may be as result of hacking, etc. we may restore the SIM account for the user. But if we receive unsatisfactory answers, we may proceed to terminate the SIM account accordingly.</p>
                    <p className="mb-4">eSIM/SIM Account Inactivity - While the eSIM/SIM Account remains active throughout the 12 calendar months, an eSIM/SIM account may become inactive if there are no activities such as accessing data, or placing a and/or receiving a call once the SIM is activated at least within a 6 calendar months period for up to 1 MB consumed or 1 minute call placed or otherwise, eSimlinks may suspend your eSIM/SIM account due to some regulations in the Global System of Mobile Communication (GSM) world. Suspension does not mean that your SIM account is canceled. It is only suspended due to some regulations in the GSM world. Your eSIM/SIM account will be reactivated to normal if you request if it is within the 12 calendar months' duration from the date of your first eSIM/SIM activation.</p>
                </li>
                <li>
                    <span className="heading">Governing Laws & General Information</span>
                    <p>This agreement and Your use of our Terms and General Conditions of Services are written in accordance and governed by the laws of the Commonwealth of The Bahamas where eSimlinks and its parent company (Do Something Innovative Ltd) has operational offices). All disputes or claims that may arise with eSimlinks and its affiliated and/or associated websites through its operations at the parent company (if applicable) of eSimlinks or its subsidiaries, affiliates, employees, or agents will be handled by the exclusive jurisdiction of the courts of the Bahamas and all jurisdictions where eSimlinks has operational offices. You agree that this agreement is interpreted to be fair and without creating any jeopardy against any party.</p>
                </li>
                <li>
                    <span className="heading">Registered Office/Parent Company</span>
                    <p>eSimlinks is a division of Do Something Innovative Ltd in The Bahamas.</p>
                </li>
            </ol>
        </div>
      </section>
    </HelmetProvider>
  );
};

export default TermsAndConditions;