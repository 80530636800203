import React, { useState } from 'react'
import { Link } from "react-router-dom";
import * as PR from "../../prime-modules/index";
import { visa, masterCard, appleIcon, gpayIcon } from "../../assets/images";
import { scrollToBundlesActions } from '../../store/scrollToBundles';
import { useDispatch } from 'react-redux';
import { scrollTop } from "../../utils/reuse";
import { useToast } from '../../context/ToastContext';
import getAssets from "../../assets/data/brandingAssets.json"

const Footer = () => {
  const { labels, general, isBase64Image, header, globalConfig } = useToast();
  const dispatch = useDispatch();
  const [year] = useState(new Date().getFullYear()); 

  const pricingHandler = () => {
    dispatch(scrollToBundlesActions.setScrollToBundles(true));
  }

  const  isJsonString = (str)  => {
    try {
      JSON.parse(str);
      return true;
    } catch (e) {
      return false;
    }
  }
  const order = isJsonString(header?.HEAD0001) ? JSON.parse(header?.HEAD0001): [];

  return (
    <>
      <footer>
        <div className='footer-info grid grid-nogutter'>
          <div className='col-12 lg:col-3 md:col-6 footer-logo'>
            <Link to="/">
              <PR.Image className="logo" src={isBase64Image(general.GEN0002) ? `data:image/png;base64,${general.GEN0002}` : getAssets.logo.logo_footer} alt="logo" />
            </Link>
            <br />
            <span className='social-span'>{labels.LBL0063}</span>
            <ul>
              <li><Link to={"https://www.facebook.com/people/ESimlinks/61550925532859/?mibextid=LQQJ4d"} target='_blank' rel='noreferrer'className='social-image facebook'></Link></li>
              <li><Link to={"https://www.instagram.com/esimlinks/?igsh=MTNxd2tvcTJwZXM2cg%3D%3D&utm_source=qr"} target='_blank' rel='noreferrer'className='social-image instagram'></Link></li>
              <li><Link to={"https://www.youtube.com/@eSIMlinks"} target='_blank' rel='noreferrer'className='social-image youtube'></Link></li>
            </ul>
          </div>
          <div className='col-12 lg:col-3 md:col-6'>
            <h2>{labels.LBL0299}</h2>
            <div className='footer-links'>
              {order.includes('MENU001') && <Link to={"/"} onClick={scrollTop}>{header?.MENU001 ? header?.MENU001 : labels.LBL0082}</Link>}
              {order.includes('MENU002') && <Link to={"/"} onClick={pricingHandler}>{header?.MENU002 ? header?.MENU002 : labels.LBL0300}</Link>}
              {order.includes('MENU004') && <Link to="/affiliate">{header?.MENU004 ? header?.MENU004 : labels.LBL0304}</Link>}
              {order.includes('MENU005') && <Link to="/about-us">{header?.MENU005 ? header?.MENU005 : labels.LBL0084}</Link>}
              {order.includes('MENU006') && <Link to={"/contact"} >{header?.MENU006 ? header?.MENU006 : labels.LBL0085}</Link>}
            </div>
          </div>
          <div className='col-12 lg:col-3 md:col-6'>
            <h2>{labels.LBL0301}</h2>
            <div className='footer-links'>
              <Link to="/faq">{labels.LBL0083}</Link>
              <Link to="/terms-and-conditions">{labels.LBL0178}</Link>
              <Link to="/privacy-policy">{labels.LBL0075}</Link>
            </div>
          </div>
          <div className='col-12 lg:col-3 md:col-6'>
            <h2>{labels.LBL0170}</h2>
            <div className='footer-links'>
              <Link to={`tel:${globalConfig.phoneNumber}`}>{globalConfig.phoneNumber}</Link>
              <Link to={`mailto:${globalConfig.supportEmail}`}>{globalConfig.supportEmail}</Link>
            </div>
          </div>
        </div>
        <div className='grid grid-nogutter align-items-center justify-content-between copyrights-div'>
          <p>&copy;{year} <Link to="/">{globalConfig.domainName}.</Link> {labels.LBL0065} | {labels.LBL0064} <Link target='_blank' className='underline' to="https://www.bluehouse-technology.co.uk/">Bluehouse Technology.</Link></p>
          <div className='right'>
            <span>{globalConfig.domainName + " " + labels.LBL0086}</span>
            <ul className='flex align-items-center'>
              <li><PR.Image src={visa} alt="Visa" /></li>
              <li><PR.Image src={masterCard} alt="Master Card" /></li>
              <li><PR.Image src={appleIcon} alt="Apple Pay" /></li>
              <li><PR.Image src={gpayIcon} alt="Google Pay" /></li>
            </ul>
          </div>
        </div>
        <PR.ScrollTop className='scroll-to-top' icon="pi pi-arrow-up" />
      </footer>
    </>
  )
}
export default Footer