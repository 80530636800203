import React from "react";
import "../privacy-policy/PrivacyPolicy.scss";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import HeaderMenu from "../../components/layout/HeaderMenu";
import { Link } from "react-router-dom";
import { useToast } from "../../context/ToastContext";

const PrivacyPolicy = () => {
    const { labels, globalConfig, general } = useToast();
  return (
     <HelmetProvider> 
      <Helmet>
          <meta charSet="utf-8" />
          <title>{general.GEN0009} | {labels.LBL0075}</title>
      </Helmet>

    <section className="tc-pp-section">
        <div className="header">
            <header>
                <HeaderMenu />
            </header>
            <div className="fixed-height"></div>
        </div>
    </section>

    <section className="about-blocks-section tc-block">
        <div className="privacy-policy-section terms-and-conditions-section">
            <div className="mb-5">
                <h1 className="main-heading">{labels.LBL0075}</h1>
                <h2 className="sub-heading">{labels.LBL0321}</h2>
            </div>
            <p>{globalConfig.domainName} respects your privacy and we do not sell, rent, or loan any personal identifiable information regarding our customers to any third party. We want to assure you that your information is kept confidential as required by law.</p>
            <p>However, we do collect information on products browsed on our website through log files and information as such is generally used to increase value to our numerous customers. Any time an order is placed, we collect basic information including your name, contact address, phone and fax number and email address. We use this information to confirm your order and to respond to any concerns you may have at any time. Where applicable, such information is also shared with third parties such as (USPS, FedEx or applicable jurisdiction carrier & other) of our partners basically to process and ship order/s you have made. You may be included in our list to receive email updates about special offers, new products and services. If for any reason you do not wish to receive this information, you may reply to any of our emails and type "unsubscribe" in the subject line. You may opt-out of receiving future mailings at any time.</p>
            <p>{globalConfig.domainName} has security measures in place to protect against the loss, misuse, and alteration of the information that we collect. Security utilizes up-to-date security measures to keep information collected much secured. Your internet protocol address may be used to help us identify you and other information supplied when ordering a product from our site.</p>
            <p>If you have further questions about our privacy policy or have some useful comments on how we can improve on our site and serve our customers better, please feel free to send an email to us at: <Link to={`mailto:${globalConfig.supportEmail}`} target='_top' rel="noreferrer">{globalConfig.supportEmail}</Link></p>
            <h2 className="mb-0">Why we use cookies</h2>
            <p>We use Cookies in compliance with the EU cookie law GDRP regulations. In addition, we use cookies to ensure smooth functionality of our site. To know more about the EU cookie law GDRP, please click on the link below: </p>
            <p><Link to="http://ec.europa.eu/ipg/basics/legal/cookies/index_en.htm" target="_blank">http://ec.europa.eu/ipg/basics/legal/cookies/index_en.htm</Link></p>
        </div>
      </section>
    </HelmetProvider>
  );
};

export default PrivacyPolicy;