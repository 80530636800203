import React, { useEffect } from "react";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { Link } from "react-router-dom";
import * as PR from "../../prime-modules/index";
import { airlineIcon, travelIcon, ukTravelIcon } from "../../assets/images";
import { useTranslation } from "react-i18next";
import HeaderMenu from "../../components/layout/HeaderMenu";
import "../home/Home.scss";
import "../affiliate/Affiliate.scss";
import { useDispatch, useSelector } from "react-redux";
import { scrollToBundlesActions } from "../../store/scrollToBundles";
import { envConfig } from "../../constants";
import { getAssetsData } from "../../services/api";
import { messageCatalogActions } from "../../store/messageCatalog";
import { useToast } from "../../context/ToastContext";
import getAssets from '../../assets/data/brandingAssets.json';

const Affiliate = () => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const scrollToBundlesState = useSelector(state => state.scrollToBundles.scrollToBundlesState);
  const {affiliate, isBase64Image, labels, globalConfig,  general} = useToast()
  useEffect(() => {
    const getResponse = (response) => {
      if (response.result === "SUCCESS") {
        dispatch(messageCatalogActions.storeAffiliate(response.data))
      }
    }
    getAssetsData(i18n.language, 'affiliate', getResponse)
  }, [i18n.language, dispatch])

  useEffect(() => {
    if(scrollToBundlesState) {
      dispatch(scrollToBundlesActions.desleteSrollToBundles());
    }
  }, [t, i18n, scrollToBundlesState, dispatch]);

  return (
    <HelmetProvider>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{`${general.GEN0001} | ${labels.LBL0304}`}</title>
      </Helmet>

      {/* Header Section */}
      <section className="header-section affiliate-header-section">
        <div className="header">
          <header>
            <HeaderMenu />
          </header>
          <div className="fixed-height"></div>
        </div>
        <div className="grid header-text">
          <div className="col-12 text-center">
            <div className="header-text-box">
              <h1>{affiliate?.AFF0002}</h1>
              <p>{affiliate?.AFF0003}</p>
              <Link
                to={envConfig.REACT_APP_AFFILIATE_DOMAIN_URL + '/signup'}
                target='_blank'
                className="go-to-home"><b>{affiliate?.AFF0004}</b>
              </Link>
            </div>
          </div>
        </div>
      </section>

      {/* Options Section */}
      <section className="options-section affiliate-options">
        <div className="grid">
          <div className="col-12 lg:col-4 md:col-4">
            <div className="option-box">
              <PR.Image src={isBase64Image(affiliate.AFF0007) ? `data:image/png;base64,${affiliate.AFF0007}` : getAssets.affiliate.easy_to_start} alt="Instant Activation" />
              <h2>{affiliate?.AFF0005}</h2>
              <p>{affiliate?.AFF0006}</p>
            </div>
          </div>
          <div className="col-12 lg:col-4 md:col-4">
            <div className="option-box">
              <PR.Image src={isBase64Image(affiliate.AFF0010) ? `data:image/png;base64,${affiliate.AFF0010}` : getAssets.affiliate.dedicated_dashboard} alt="Attractive Rates" />
              <h2>{affiliate?.AFF0008}</h2>
              <p>{affiliate?.AFF0009}</p>
            </div>
          </div>
          <div className="col-12 lg:col-4 md:col-4">
            <div className="option-box small-content-box">
              <PR.Image src={isBase64Image(affiliate.AFF0013) ? `data:image/png;base64,${affiliate.AFF0013}` : getAssets.affiliate.upfront_investment} alt="Global Coverage" />
              <h2>{affiliate?.AFF0011}</h2>
              <p>{affiliate?.AFF0012}</p>
            </div>
          </div>
        </div>
      </section>

      {/* Affiliate Program Section */}
      <section className="offer-section affiliate-program-section">
        <div className="grid column-reverse">
          <div className="col-12 lg:col-6 md:col-6 p-0 text-center">
              <PR.Image
                className="affiliate-program-image"
                src={isBase64Image(affiliate.AFF0014) ? `data:image/png;base64,${affiliate.AFF0014}` : getAssets.affiliate.affiliate_program}
                alt="Connectivity"
              />
          </div>
          <div className="col-12 lg:col-6 md:col-6 p-0">
            <div className="option-box">
                <h2>{affiliate?.AFF0015}</h2>
                <p>{affiliate?.AFF0016}</p>
                <Link
                to={envConfig.REACT_APP_AFFILIATE_DOMAIN_URL + '/signup'}
                target='_blank'
                className="choose-plans-button"><b>{affiliate?.AFF0017}</b>
              </Link>
            </div>
          </div>
        </div>
      </section>

      {/* Steps Section */}
      <section className="plan-section steps-section">
        <div className="align-items-center">
          <h2>{affiliate?.AFF0018}</h2>
          <p className="steps-description-p-tag">{affiliate?.AFF0019}</p>
          <div className="card">
              <div className="grid">
                  <div className="col-12 lg:col-4 md:col-4">
                    <div className="relative">
                      <div className="steps-box">
                        <div className="steps-icon">
                          <PR.Image src={isBase64Image(affiliate.AFF0020) ? `data:image/png;base64,${affiliate.AFF0020}` : getAssets.affiliate.step1} alt="Step One" /> 
                        </div>
                        <h3 className="step-one">
                          <p>01</p>
                          {affiliate?.AFF0021}
                        </h3>
                      </div>
                      <div className="stepsnumber"></div>
                    </div>
                  </div>
                  <div className="col-12 lg:col-4 md:col-4">
                    <div className="relative">
                      <div className="steps-box">
                        <div className="steps-icon secondary-color">
                          <PR.Image src={isBase64Image(affiliate.AFF0022) ? `data:image/png;base64,${affiliate.AFF0022}` : getAssets.affiliate.step2} alt="Step Two" />
                        </div>
                        <h3>
                          <p className="secondary-color">02</p>
                          {affiliate?.AFF0023}
                        </h3>
                      </div>
                        <div className="stepsnumber secondary-color"></div>
                    </div>
                  </div>
                  <div className="col-12 lg:col-4 md:col-4">
                      <div className="relative">
                        <div className="steps-box">
                          <div className="steps-icon">
                            <PR.Image src={isBase64Image(affiliate.AFF0024) ? `data:image/png;base64,${affiliate.AFF0024}` : getAssets.affiliate.step3} alt="Step Three" />
                          </div>
                          <h3>
                            <p>03</p>
                            {affiliate?.AFF0025}
                          </h3>
                        </div>
                        <div className="stepsnumber"></div>
                      </div>
                  </div>
              </div>
          </div>
          <p className="steps-description-p-tag">{affiliate?.AFF0026}</p>
        </div>
      </section>

      <section className="plan-section sales-info-section">
        <div className="align-items-center">
          <h2>{affiliate?.AFF0027}</h2>
          <div className="card info-box">
              <div className="div-row first-row">
                 <div className="left">
                    <ul className="flex align-items-center">
                      <li>
                        <PR.Image
                          src={airlineIcon}
                          alt="Connectivity"
                        />
                      </li>
                      <li>
                        <h3>{affiliate?.AFF0059}</h3>
                        <p>{affiliate?.AFF0060}</p>
                      </li>
                    </ul>
                 </div>
                 <div className="right">
                    <p>58,000 {globalConfig.domainName} {affiliate?.AFF0061}</p>
                    <p><span>$88,000 / {affiliate?.AFF0062}</span></p>
                 </div>
              </div>
              <div className="div-row">
                 <div className="left">
                    <ul className="flex align-items-center">
                      <li>
                        <PR.Image
                          src={travelIcon}
                          alt="Connectivity"
                        />
                      </li>
                      <li>
                        <h3>{affiliate?.AFF0063}</h3>
                        <p>{affiliate?.AFF0064}</p>
                      </li>
                    </ul>
                 </div>
                 <div className="right">
                    <p>20,000 {globalConfig.domainName} {affiliate?.AFF0061}</p>
                    <p><span>$60,000 / {affiliate?.AFF0062}</span></p>
                 </div>
              </div>
              <div className="div-row">
                 <div className="left">
                    <ul className="flex align-items-center">
                      <li>
                        <PR.Image
                          src={ukTravelIcon}
                          alt="Connectivity"
                        />
                      </li>
                      <li>
                        <h3>{affiliate?.AFF0065}</h3>
                        <p>{affiliate?.AFF0066}</p>
                      </li>
                    </ul>
                 </div>
                 <div className="right">
                    <p>350 {globalConfig.domainName} {affiliate?.AFF0061}</p>
                    <p><span>$950 / {affiliate?.AFF0062}</span></p>
                 </div>
              </div>
              <p className="info_p">{affiliate?.AFF0067}</p>
          </div>
        </div>
      </section>

      {/* Partners Section */}
      <section className="partners-section getting-started-section">
        <div className="grid align-items-center">
          <div className="col-12 lg:col-6 md:col-6">
            <div className="partners-text-box getting-started-box">
              <div>
                <h2>{affiliate?.AFF0028}</h2>
                <p>{affiliate?.AFF0029}<br/> {affiliate?.AFF0030}</p>
                <ul>
                  <li><span className="number">1</span></li>
                  <li>
                    <h3>{affiliate?.AFF0031}</h3>
                    <p>{affiliate?.AFF0032}</p>
                  </li>
                </ul>
                <ul>
                  <li><span className="number">2</span></li>
                  <li>
                    <h3>{affiliate?.AFF0033}</h3>
                    <p>{affiliate?.AFF0034}</p>
                  </li>
                </ul>
                <ul>
                  <li><span className="number">3</span></li>
                  <li>
                    <h3>{affiliate?.AFF0035}</h3>
                    <p>{affiliate?.AFF0036}</p>
                    <Link className="view-all-button" to={envConfig.REACT_APP_AFFILIATE_DOMAIN_URL + '/signup'} target="_blank">{affiliate?.AFF0037}</Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="col-12 lg:col-6 md:col-6">
            <div className="getting-started-right-box" style={{ background: `url(${isBase64Image(affiliate.AFF0038) ?`data:image/png;base64,${affiliate.AFF0038}` : getAssets.affiliate.affiliate_right_image}) no-repeat` }}>
              <div className="info">
                <h2>{affiliate?.AFF0039}</h2>
                <Link className="view-all-button btn-white" to={envConfig.REACT_APP_AFFILIATE_DOMAIN_URL + '/signup'} target="_blank">{affiliate?.AFF0040}</Link>
                <Link className="view-all-button" to='/faq'>{affiliate?.AFF0041}</Link>
              </div>
            </div>
          </div>
        </div>
      </section>

    </HelmetProvider>
  );
};

export default Affiliate;