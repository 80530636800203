import React, { useState, useEffect, useCallback, useMemo } from "react";
import { useDispatch, useSelector } from 'react-redux';
import {
    useStripe,
    useElements,
    PaymentRequestButtonElement,
} from '@stripe/react-stripe-js';
import * as PR from "../../prime-modules/index";
import { envConfig } from "../../constants";
import { stripePayment } from "../../services/api";
import { v4 as uuidv4 } from 'uuid';
import { cartFilterActions } from "../../store/cart";
import { useToast } from "../../context/ToastContext";
import { getPhoneObj } from '../../shared/methods/GetAllCountries';

const GooglePayPayment = ( props ) => {
    const { showToast, globalConfig  } = useToast()
    const dispatch = useDispatch();
    const stripe = useStripe();
    const elements = useElements();
    const [paymentRequest, setPaymentRequest] = useState(null);
    const [display3DPopup, setDisplay3DPopup] = useState(false);
    const [auth3dSecureLink, setAuth3dSecureLink] = useState('');
    const orderAmount = useSelector((state) => state.cart.catalogCartAmount);
    const cartData = useSelector((state) => state.cart.catalogCart);
    const iccid = useSelector(state => state.esim.esimIccid);
    const isAuth = useSelector(state => state.auth.isLoggedIn);
    const userData = useSelector(state => state.auth.sessionData);
    const randomId = useSelector((state) => state.cart.randomId);
    const affiliatePath = useSelector(state => state.auth.affiliatePath);
    const affiliateTrackingData = useSelector(state => state.affiliateTracking.affiliateTrackingInfo ? state.affiliateTracking.affiliateTrackingInfo : affiliatePath?.afid);
    const headers = useMemo(() => {
        return { sessionid: userData.sessionId, afid: affiliateTrackingData };
    }, [userData.sessionId, affiliateTrackingData]);
    const totalPayAmount = ('promoCode' in cartData) ? cartData.discountedPrice : cartData.price;

    const on3DSecurePopupHide = useCallback(() => {
        setDisplay3DPopup(false)
        setAuth3dSecureLink('');
        props.paymentProps("", false);
    }, [props])

    const paymentResponse = useCallback((payRes) => {
        if (payRes.status === "succeeded") {
            dispatch(cartFilterActions.removeRandomIdInPayment());
            on3DSecurePopupHide()
            props.paymentProps(payRes, false);
        } else if (payRes.status === "requires_action") {
            props.paymentProps("", true);
            setAuth3dSecureLink(payRes.authURL);
            setDisplay3DPopup(true);
        } else {
            props.paymentProps("", false)
            on3DSecurePopupHide();
            props.setPaymentType("");
            showToast("error", payRes.error)
        }
    }, [dispatch, on3DSecurePopupHide, props, showToast])

    const createGooglePayPayment = useCallback((paydetails) => {
        const getPaymentData = (response) => {
            props.paymentProps("", false);
            if (response.result === "SUCCESS") {
                paymentResponse(response.data);
            } else {
                props.paymentProps("", false)
                const error = response.error;
                props.setPaymentType("");
                showToast(error.severity, error.errorMsg);
            }
        }

        const paymentMethodID = paydetails.paymentMethod.id
        if (paymentMethodID && paymentMethodID !== '') {
            const getCartObj = { ...cartData };
            delete getCartObj.discountedPercentage;
            delete getCartObj.discountedPrice;
            delete getCartObj.dataAmountForDisplay;
            delete getCartObj.promoCodePrice;

            const dataPlans = [getCartObj];
            const payerEmail = paydetails.payerEmail;
            let payerName = paydetails.payerName;
            if (payerName === "Card Holder Name") {
                payerName = payerEmail.split('@')[0];
            }
            const customerDetails = {
                firstName: payerName,
                lastName: "",
                email: payerEmail,
                phone: getPhoneObj('', isAuth, userData, '')
            }
            const deviceInfo = { device: globalConfig.deviceInfo };

            const paymentData = {
                contactInfo: customerDetails,
                orderSummary: {
                    dataPlans: dataPlans,
                    totalOrderAmount: orderAmount
                },
                paymentGateway: "GooglePay",
                paymentMethodId: paymentMethodID,
                iccid: iccid,
                isEsimCompatible: globalConfig.isEsimCompatible,
                deviceInfo: deviceInfo,
                randomId:randomId ? randomId : uuidv4()
            }
            stripePayment(paymentData, headers, dispatch, getPaymentData)
        }
    }, [cartData, dispatch, globalConfig.deviceInfo, globalConfig.isEsimCompatible, headers, iccid, isAuth, orderAmount, paymentResponse, props, randomId, showToast, userData])


    const googlePayment = useCallback(() => {
        if (!stripe || !elements) {
            return;
        }
        const orderTotal = parseInt(totalPayAmount * 100);
        const stripeRequestData = {
            country: globalConfig.paymentDefaultCountry,
            currency: globalConfig.paymentDefaultCurrency,
            total: {
                label: globalConfig.paymentDefaultLabel,
                amount: orderTotal,
            },
            requestPayerName: true,
            requestPayerEmail: true,
        }
        const pr = stripe.paymentRequest(stripeRequestData);
        pr.canMakePayment().then((result) => {
            if (result && result.googlePay) {
                setPaymentRequest(pr)
            }
        });
        pr.on('paymentmethod', async (e) => {
            const payMethodID = e.paymentMethod.id;
            if (payMethodID) {
                props.paymentProps("", true);
                createGooglePayPayment(e);
                e.complete('success');
            } else {
                props.paymentProps("", false);
                props.setPaymentType("");
                showToast('error', "PMTMSG-15")
                e.complete('fail');
            }
        });
    }, [stripe, elements, totalPayAmount, globalConfig.paymentDefaultCountry, globalConfig.paymentDefaultCurrency, globalConfig.paymentDefaultLabel, props, createGooglePayPayment, showToast])


    useEffect(() => {
        googlePayment();
    }, [googlePayment])

    const handleIframe = () => {
        const recieveIframeMsg = (event) => {
            if (event.origin === envConfig.REACT_APP_BASE_URL) {
                paymentResponse(event.data)
            }
        }
        window.addEventListener('message', recieveIframeMsg, false);
    }


    return (
        <>
            <div className="col-12 md:col-12">
                <div className="input-wrapper paypal-section-button payment-section">
                    {paymentRequest && <PaymentRequestButtonElement options={{ paymentRequest }} />}
                </div>
            </div>

            <PR.Dialog header="" visible={display3DPopup} breakpoints={{ '960px': '85vw' }} style={{ width: '35vw' }} onHide={() => on3DSecurePopupHide()} resizable={false}>
                <iframe title="3d Secure" src={auth3dSecureLink} width="100%" id="secure3dRef" className="payment-iframe" onLoad={handleIframe} />
            </PR.Dialog>
        </>
    );
};

export default GooglePayPayment;