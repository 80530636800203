import React, { useRef, useEffect, useState } from "react";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { Link } from "react-router-dom";
import * as PR from "../../prime-modules/index";
import { useTranslation } from "react-i18next";
import BundlesList from "../../components/EsimBundles";
import HeaderMenu from "../../components/layout/HeaderMenu";
import "../home/Home.scss";
import faq_en from "../../assets/data/faq_en.json";
import faq_es from "../../assets/data/faq_es.json";
import faq_pt from "../../assets/data/faq_pt.json";
import faq_fr from "../../assets/data/faq_fr.json";
import faq_de from "../../assets/data/faq_de.json";
import { useDispatch, useSelector } from "react-redux";
import { scrollToBundlesActions } from "../../store/scrollToBundles";
import { ScrollTo } from "../../utils/ScrollTo";
import { useToast } from "../../context/ToastContext";
import { messageCatalogActions } from "../../store/messageCatalog";
import { getAssetsData } from "../../services/api";
import getAssets from "../../assets/data/brandingAssets.json"

const Home = () => {
  const { i18n } = useTranslation();
  const { labels, home, general, isBase64Image } = useToast()
  const dispatch = useDispatch();
  const scrollToBundlesState = useSelector(state => state.scrollToBundles.scrollToBundlesState);
  const ref = useRef(null);
  const [faq, setFaq] = useState([]);

  const handleClick = () => {
    ScrollTo(ref, -20);
  };

  useEffect(() => {
    if (scrollToBundlesState) {
      handleClick();
      dispatch(scrollToBundlesActions.desleteSrollToBundles());
    }

    const defaultLang = i18n.language.split("-")[0];
    const faqData = {
      en: faq_en,
      es: faq_es,
      pt: faq_pt,
      fr: faq_fr,
      de: faq_de,
    };

     if (faqData[defaultLang]) {
      setFaq(faqData[defaultLang]);
    }
  }, [i18n.language, scrollToBundlesState, dispatch]);

  useEffect(() => {
    const getResponse = (response) => {
      if (response.result === "SUCCESS") {
        dispatch(messageCatalogActions.storeHome(response.data))
      }
    }
    getAssetsData(i18n.language, 'home', getResponse)
  }, [i18n.language, dispatch])

  return (
    <HelmetProvider>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{general.GEN0009}</title>
        <meta property="og:description" content={general.GEN0010} />
      </Helmet>

      {/* Header Section */}
      <section className="header-section">
        <div className="header">
          <header>
            <HeaderMenu />
          </header>
          <div className="fixed-height"></div>
        </div>
        <div className="grid header-text">
          <div className="col-12 lg:col-5 md:col-5">
            <div className="header-text-box">
              <h1>{home.HOME0002}</h1>
              <p>{home.HOME0003}</p>
              <PR.Button
                label={home.HOME0004}
                className="goto-plans-button p-button-rounded"
                onClick={handleClick}
              />
            </div>
          </div>
          <div className="col-12 lg:col-7 md:col-7">
            <PR.Image
              className="banner-img"
              src={isBase64Image(home.HOME0001) ? `data:image/png;base64,${home.HOME0001}` : getAssets.home.header_banner}
              alt="No Ban Aid needed"
            />
          </div>
        </div>
      </section>

      {/* Options Section */}
      <section className="options-section">
        <div className="grid">
          <div className="col-12 lg:col-4 md:col-4">
            <div className="option-box">
              <PR.Image src={isBase64Image(home.HOME0007) ? `data:image/png;base64,${home.HOME0007}` : getAssets.home.instant_activation} alt="Instant Activation" />
              <h2>{home.HOME0005}</h2>
              <p>{home.HOME0006}</p>
            </div>
          </div>
          <div className="col-12 lg:col-4 md:col-4">
            <div className="option-box">
              <PR.Image src={isBase64Image(home.HOME0010) ? `data:image/png;base64,${home.HOME0010}` : getAssets.home.attractive_rates} alt="Attractive Rates" />
              <h2>{home.HOME0008}</h2>
              <p>{home.HOME0009}</p>
            </div>
          </div>
          <div className="col-12 lg:col-4 md:col-4">
            <div className="option-box">
              <PR.Image src={isBase64Image(home.HOME0013) ? `data:image/png;base64,${home.HOME0013}` : getAssets.home.global_coverage} alt="Global Coverage" />
              <h2>{home.HOME0011}</h2>
              <p>{home.HOME0012}</p>
            </div>
          </div>
        </div>
      </section>

      {/* Offer Section */}
      <section className="offer-section">
        <div className="grid column-reverse">
          <div className="col-12 lg:col-6 md:col-6 p-0 text-center">
            <PR.Image
              className="hand-iphone-img"
              src={isBase64Image(home.HOME0014) ? `data:image/png;base64,${home.HOME0014}` : getAssets.home.hand_iphone}
              alt="Connectivity"
            />
          </div>
          <div className="col-12 lg:col-6 md:col-6 p-0">
            <div className="option-box">
              <h2>{home.HOME0015}</h2>
              <p>{home.HOME0016}</p>
              <PR.Button label={home.HOME0017} className="choose-plans-button p-button-rounded" onClick={handleClick} />
            </div>
          </div>
        </div>
      </section>

      {/* Plans Section */}
      <section className="plan-section" ref={ref}>
        <div className="grid align-items-center">
          <h2>{labels.LBL0296}</h2>
        </div>
        <div className="card">
          <div className="bundles-section">
            <BundlesList />
          </div>
        </div>
      </section>
      {/* Benefits Section */}
      <section className="benefits-section">
        <div className="grid">
          <div className="col-12 text-center md:mb-7">
            <h2>{labels.LBL0139}</h2>
          </div>
          <div className="col-12 lg:col-6 md:col-6 text-center">
            <div className="option-box">
              <PR.Image
                className="benefits-img"
                src={isBase64Image(home.HOME0033) ? `data:image/png;base64,${home.HOME0033}` : getAssets.home.benefits_image}
                alt="Benefits"
              />
            </div>
          </div>
          <div className="col-12 lg:col-6 md:col-6">
            <div className="option-box">
              <div className="flex align-items-center gap-4 mb-5">
                <div className="benefits-icon">
                  <PR.Image
                    className="hand-iphone-img"
                    src={isBase64Image(home.HOME0020) ? `data:image/png;base64,${home.HOME0020}` : getAssets.home.best_pricing_plans}
                    alt="Multi-Network Connectivity"
                  />
                </div>
                <div className="benefits-text">
                  <h3>{home.HOME0018}</h3>
                  <p>{home.HOME0019}</p>
                </div>
              </div>

              <div className="flex align-items-center gap-4 mb-5">
                <div className="benefits-icon">
                  <PR.Image
                    className="hand-iphone-img"
                    src={isBase64Image(home.HOME0023) ? `data:image/png;base64,${home.HOME0023}` : getAssets.home.easy_installation}
                    alt="Fast Connections"
                  />
                </div>
                <div className="benefits-text">
                  <h3>{home.HOME0021}</h3>
                  <p>{home.HOME0022}</p>
                </div>
              </div>

              <div className="flex align-items-center gap-4 mb-5">
                <div className="benefits-icon">
                  <PR.Image
                    className="hand-iphone-img"
                    src={isBase64Image(home.HOME0026) ? `data:image/png;base64,${home.HOME0026}` : getAssets.home.multi_network_connectivity}
                    alt="Support 24/7"
                  />
                </div>
                <div className="benefits-text">
                  <h3>{home.HOME0024}</h3>
                  <p>{home.HOME0025}</p>
                </div>
              </div>

              <div className="flex align-items-center gap-4 mb-5">
                <div className="benefits-icon">
                  <PR.Image
                    className="hand-iphone-img"
                    src={isBase64Image(home.HOME0029) ? `data:image/png;base64,${home.HOME0029}` : getAssets.home.fast_connections}
                    alt="Easy Installation"
                  />
                </div>
                <div className="benefits-text">
                  <h3>{home.HOME0027}</h3>
                  <p>{home.HOME0028}</p>
                </div>
              </div>

              <div className="flex align-items-center gap-4">
                <div className="benefits-icon">
                  <PR.Image
                    className="hand-iphone-img"
                    src={isBase64Image(home.HOME0032) ? `data:image/png;base64,${home.HOME0032}` : getAssets.home.support_24_7}
                    alt="Best Pricing Plans"
                  />
                </div>
                <div className="benefits-text">
                  <h3>{home.HOME0030}</h3>
                  <p>{home.HOME0031}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* FAQ Section */}
      <section className="faq-section">
        <div className="grid align-items-center">
          <div className="col-12 lg:col-10 md:col-10 md:col-offset-1 lg:col-offset-1">
            <h2>{labels.LBL0136}</h2>
            {faq &&
              faq.length > 0 &&
              faq.slice(0, 1).map((item, i) => (
                <PR.Accordion key={i}>
                  {item.QA.slice(0, 4).map((qa, j) => (
                    <PR.AccordionTab header={qa.question} key={j}>
                      {qa.answer.map((an, h) => (
                        <div key={h}>
                          {an.content_heading && (
                            <p><strong>{an.content_heading}</strong></p>
                          )}
                          {an.content && (
                            <p>{an.content}</p>
                          )}
                          {an.olist?.length > 0 && (
                            <ol>
                              {an.olist.map((anl, nh) => (
                                <li key={nh}>{anl}</li>
                              ))}
                            </ol>
                          )}
                          {an.ulist?.length > 0 && (
                            <ul>
                              {an.ulist.map((anl, nh) => (
                                <li key={nh}>{anl}</li>
                              ))}
                            </ul>
                          )}
                        </div>
                      ))}
                    </PR.AccordionTab>
                  ))}
                </PR.Accordion>
              ))}
          </div>
        </div>
        <div className="text-center faq-custom-margin">
          <Link to="/faq" className="view-all-button">{labels.LBL0324}</Link>
        </div>
      </section>
    </HelmetProvider>
  );
};

export default Home;