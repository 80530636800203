import React, { useEffect, useState, useMemo } from "react";
import * as PR from "../prime-modules/index";
import { Link } from "react-router-dom";
import { resetPassword } from "../services/api";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from 'yup';
import { useFormik } from "formik";
import { authActions } from "../store/auth";
import { removeWhiteSpacesInPassword } from "../utils/reuse";
import { useToast } from "../context/ToastContext";

const ResetPassword = (props) => {
    const dispatch = useDispatch();
    const { showToast, labels, validations } = useToast();
    const [disableBtn, setDisableBtn] = useState(false);
    const passwordResetUUID = useSelector(state => state.auth.idPath.userId);
    const affiliatePath = useSelector(state => state.auth.affiliatePath);
    const affiliateTrackingData = useSelector(state => state.affiliateTracking.affiliateTrackingInfo ? state.affiliateTracking.affiliateTrackingInfo : affiliatePath?.afid);
    const headers = useMemo(() => {
        return {afid: affiliateTrackingData};
    }, [affiliateTrackingData]);

    useEffect(() => {
        if (!passwordResetUUID) {
            showToast("error", " RSTPWD-04")
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [showToast])

    const formInitialValues = {
        password: '',
        confirmPassword: ''
    };
    const validationSchema = () => {
        return Yup.object().shape({
            password: Yup.string().trim()
            .required("VLD0001")
            .min(8, "VLD0002")
            .max(20, "VLD0002")
            .matches(/\d/, "VLD0002")
            .matches(/[A-Za-z]/, "VLD0002")
            .strict(true),
            confirmPassword: Yup.string().trim()
                .required("VLD0003")
                .when("password", {
                    is: val => (val && val.length > 0 ? true : false),
                    then: Yup.string().oneOf(
                        [Yup.ref("password")],
                        "VLD0004"
                )
            })
        });
    }
    const submitHandler = (formData) => {
        setDisableBtn(true);
        const getResponse = (response) => {
            if (response.result === "SUCCESS") {
                showToast("success", "RSTPWD-05"); // resetPwd.successMessage
                formik.resetForm();
                setTimeout(() => {
                    setDisableBtn(false);
                    props.renderFormState(false, "")
                    dispatch(authActions.deleteStoreIdPath());
                }, 3000);
            } else {
                const error = response.error;
                showToast(error.severity, error.errorMsg);
                setDisableBtn(false);
            }
        }
        const resetPasswordRequest = {
            passwordResetUUID: passwordResetUUID,
            password: formData.password
        }
        resetPassword(resetPasswordRequest, headers, dispatch, getResponse)
    }
    const formik = useFormik({
        initialValues: formInitialValues,
        validationSchema: validationSchema,
        onSubmit: submitHandler
    });
    return (
        <>
             <div className="form-section forgot-password-section">
                <div className="form">
                    <h3>{labels.LBL0039}</h3>
                    <p>{labels.LBL0040}</p>
                    <form onSubmit={formik.handleSubmit} autoComplete="off">
                        <div className="input-field">
                            <PR.Password feedback={false} id='password' name='password' placeholder={labels.LBL0041} className="w-12" toggleMask
                                value={formik.values.password} onChange={e => removeWhiteSpacesInPassword(e, formik, "password")} onBlur={formik.handleBlur} />
                            {formik.errors.password && formik.touched.password
                                ? <div className='error-message'>{validations[formik.errors.password]}</div>
                                : ''
                            }
                        </div>
                        <div className="input-field">
                            <PR.Password feedback={false} id='confirmPassword' name='confirmPassword' placeholder={labels.LBL0042} className="w-12" toggleMask 
                                value={formik.values.confirmPassword} onChange={e => removeWhiteSpacesInPassword(e, formik, "confirmPassword")} onBlur={formik.handleBlur} />
                            {formik.errors.confirmPassword && formik.touched.confirmPassword
                                ? <div className='error-message'>{validations[formik.errors.confirmPassword]}</div>
                                : ''
                            }
                        </div>
                        <PR.Button label={labels.LBL0043} type='submit' disabled={disableBtn} className="login-btn signup-btn" />
                        <div className="text-center mt-5 px-6"><p>{labels.LBL0036} <Link onClick={() => props.renderFormState(true, 'signup')}>{labels.LBL0038}</Link></p></div>
                    </form>
                </div>
            </div>
        </>
    );
};

export default ResetPassword;