import React, { useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as PR from '../../prime-modules'
import AppleSignin from 'react-apple-signin-auth';
import { appleLogin } from '../../services/api';
import { authActions } from '../../store/auth';
import { esimIccidActions } from '../../store/esim';
import { envConfig } from "../../constants";
import PageLoading from "../layout/PageLoading";
import { applePay } from "../../assets/images";
import { useNavigate } from "react-router-dom";
import { getAllCountries } from "../../shared/methods/GetAllCountries";
import { useToast } from "../../context/ToastContext";


const AppleLoginComponent = (args) => {
    const {showToast, labels} = useToast()
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const countryCode = envConfig.REACT_APP_DEFAULT_PHONE_COUNTRY.toUpperCase();
    const affiliatePath = useSelector(state => state.auth.affiliatePath);
    const affiliateTrackingData = useSelector(state => state.affiliateTracking.affiliateTrackingInfo ? state.affiliateTracking.affiliateTrackingInfo : affiliatePath?.afid);

    const headers = useMemo(() => {
        return { afid: affiliateTrackingData };
    }, [affiliateTrackingData]);

    const appleResponse = res => {
        setLoading(true);
            if (!res.error) {
                const getResponse = response => {
                    if (response.result === "SUCCESS") {
                        setLoading(false);
                        const callingCode = response.data.phone?.callingCode ? response.data.phone?.callingCode : '';
                        getAllCountries(showToast, dispatch, callingCode, countryCode);

                        getAllCountries(showToast, dispatch, callingCode, '');
                        dispatch(authActions.onLogin(response.data));
                        dispatch(esimIccidActions.deleteEsim());
                            args?.renderFormState(false, "")
                            navigate("/");
                    } else if (response.result === "FAILED") {
                        setLoading(false)
                        const error = response.error;
                        const errorMsg = (error.errorMsg) ? error.errorMsg : error.summary;
                        showToast("error",errorMsg)
                    }
                }
                appleLogin(res, headers, dispatch, getResponse)
            } else {
                setLoading(false)
                showToast("error",labels?.LBL0283)
            }
    };

    return (
        <>
            <AppleSignin
                authOptions={{
                    clientId: process.env.REACT_APP_APPLE_CLIENT_ID,
                    redirectURI: process.env.REACT_APP_APPLE_CALLBACK_URL,
                    scope: 'email name',
                    state: 'state',
                    nonce: 'nonce',
                    usePopup: true,
                }}
                onSuccess={appleResponse}
                render={props => {
                    return <div target="_blank" {...props} className="social-button">
                                <PR.Image className="applePay" src={applePay}/>
                                <span className="appleBtnText">{args?.displayName}</span>
                            </div>
                }}
            />
            
            {loading && 
                <PageLoading />
            }
        </>
    );
}
 
export default AppleLoginComponent;