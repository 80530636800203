import React, { useState, useMemo, useEffect } from "react";
import * as PR from "../../prime-modules/index";
import { updateUserProfile } from "../../services/api";
import { useSelector, useDispatch } from "react-redux";
import { useFormik } from "formik";
import { authActions } from "../../store/auth";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { cartFilterActions } from "../../store/cart";
import {
  displayDefaultPhone,
  handleFirstNameChange,
  handleLastNameChange,
  trimFormData,
} from "../../utils/reuse";
import CustomPhoneNumber from "../../components/CustomPhoneNumber";
import { allCountriesActions } from "../../store/allCountries";
import { getPhoneObj } from "../../shared/methods/GetAllCountries";
import { useToast } from "../../context/ToastContext";
import { esimIccidActions } from "../../store/esim";

const Profile = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { showToast, labels, validations, account} = useToast();
  const isAuth = useSelector(state => state.auth.isLoggedIn);
  const userData = useSelector((state) => state.auth.sessionData);
  const getAllCountries = useSelector((state) => state.allCountries.allCountries);
  const getDefaultCallingCode = useSelector((state) => state.allCountries.defaultCallingCode);
  const affiliateTrackingData = useSelector(
    (state) => state.affiliateTracking.affiliateTrackingInfo
  );
  const headers = useMemo(() => {
    return { sessionid: userData.sessionId, afid: affiliateTrackingData };
  }, [userData.sessionId, affiliateTrackingData]);
  const [disableBtn, setDisableBtn] = useState(false);
  const [disableEmail, setDisableEmail] = useState(false);
  const [phoneValue, setPhoneValue] = useState('');

  const [initialValues] = useState({
    firstName: userData.firstName ?? "",
    lastName: userData.lastName ?? "",
    email: userData.email ?? "",
    phone: displayDefaultPhone(isAuth, userData, getDefaultCallingCode)
  });

  useEffect(() => {
    if (userData.signupType && userData.signupType !== "") {
      setDisableEmail(true);
    }
  }, [userData, disableEmail]);

  const validationSchema = () => {
    return Yup.object().shape({
      firstName: Yup.string()
      .trim()
      .required("VLD0007")
      .min(3, "VLD0008")
      .max(20, "VLD0008"),
      lastName: Yup.string()
        .trim()
        .required("VLD0009")
        .min(3, "VLD0010")
        .max(20, "VLD0010"),
      email: Yup.string()
        .trim()
        .email("VLD0006")
        .required("VLD0005"),
    });
  };

  const handleSubmit = (formData) => {
    setDisableBtn(true);
    const trimmedFormData = trimFormData(formData);
    const phone = getPhoneObj(phoneValue, isAuth, userData, formData);
    const emailVal = trimmedFormData.email;
    const requestObj = {
      userId: userData.userId ?? 0,
      firstName: trimmedFormData.firstName,
      lastName: trimmedFormData.lastName,
      email: emailVal,
      phone: phone,
    };
    updateUserProfile(requestObj, headers, dispatch, (response) => {
      if (response.result === "SUCCESS") {
        const filterCode = getAllCountries.filter(country => country.callingCode === phone.callingCode);
        dispatch(allCountriesActions.setPhoneCountry(filterCode[0]));
        showToast("success", "USRPRF-04")
        setTimeout(() => {
          setDisableBtn(false);
        }, [3000])
        if (userData.email !== emailVal) {
          setTimeout(() => {
            dispatch(authActions.onLogout());
            dispatch(esimIccidActions.reseteSIMS());
            dispatch(cartFilterActions.removeRandomIdInPayment());
            navigate("/login");
          }, [2000]);
        } else {
          requestObj.sessionId = userData.sessionId;
          requestObj.signupType = userData.signupType;
          dispatch(authActions.onLogin(requestObj));
        }
      } else {
        const error = response.error;
        showToast(error.severity, error.errorMsg);
      }
      setDisableBtn(false);
    });
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: handleSubmit,
  });

  return (
    <>
      <div className="profile-section">
        <div className="esim-box">
          <form autoComplete="off" onSubmit={formik.handleSubmit}>
            <div className="grid mb-3">
              <div className="col-12 lg:col-6 md:col-6">
                <div className="flex flex-column gap-2">
                  <label htmlFor="firstName">{labels.LBL0001}</label>
                  <span className="p-input-icon-right">
                    <i className="pi pi-user" />
                    <PR.InputText
                      id="firstName"
                      name="firstName"
                      placeholder={labels.LBL0001}
                      className="w-12"
                      value={formik.values.firstName}
                      onChange={(e) => handleFirstNameChange(e, formik)}
                      onBlur={formik.handleBlur}
                      autoComplete="off"
                      keyfilter={"alphanum"}
                    />
                  </span>
                  {formik.errors.firstName && formik.touched.firstName &&
                    <div className="error-message"> {validations[formik.errors.firstName]}</div>
                  }
                </div>
              </div>
              <div className="col-12 lg:col-6 md:col-6">
                <div className="flex flex-column gap-2">
                  <label htmlFor="lastName">{labels.LBL0002}</label>
                  <span className="p-input-icon-right">
                    <i className="pi pi-user" />
                    <PR.InputText
                      id="lastName"
                      name="lastName"
                      placeholder={labels.LBL0002}
                      className="w-12"
                      value={formik.values.lastName}
                      onChange={(e) => handleLastNameChange(e, formik)}
                      onBlur={formik.handleBlur}
                      autoComplete="off"
                      keyfilter={"alphanum"}
                    />
                  </span>
                  {formik.errors.lastName && formik.touched.lastName &&
                    <div className="error-message">{validations[formik.errors.lastName]} </div>
                  }
                </div>
              </div>
            </div>
            <div className="grid">
              <div className="col-12 lg:col-6 md:col-6">
                <div className="flex flex-column gap-2">
                  <label htmlFor="email">{labels.LBL0010}</label>
                  <span className="p-input-icon-right">
                    <i className="pi pi-envelope" />
                    <PR.InputText
                      id="email"
                      name="email"
                      placeholder={labels.LBL0010}
                      className="w-12"
                      value={formik.values.email}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      autoComplete="off"
                      disabled={disableEmail}
                    />
                  </span>
                  {formik.errors.email && formik.touched.email &&
                    <div className="error-message">{validations[formik.errors.email]}</div>
                  }
                </div>
              </div>
              <div className="col-12 lg:col-6 md:col-6">
                <div className="flex flex-column gap-2">
                    <label htmlFor="phone">{labels.LBL0011}</label>
                    <CustomPhoneNumber formik={formik} setPhoneValue={setPhoneValue} id="phone"/>
                </div>
              </div>
            </div>
            <div className="grid">
              <div className="col-12">
                <PR.Button
                  label={account.ACT0047}
                  type="submit"
                  disabled={!formik.isValid || disableBtn}
                  className="update-button"
                />
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default Profile;
