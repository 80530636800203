import React from 'react';
import HeaderMenu from './layout/HeaderMenu';
import * as PR from "../prime-modules/index";
import { useToast } from "../context/ToastContext";
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { scrollToBundlesActions } from '../store/scrollToBundles';

const SubHeader = (props) => {
    const { home } = useToast();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const handleClick = () => {
        navigate("/")
        dispatch(scrollToBundlesActions.setScrollToBundles(true));
      };
    return (
        <section className="inner-header-section aboutus-header-section">
            <div className="header">
                <header>
                    <HeaderMenu />
                </header>
                <div className="fixed-height"></div>
            </div>

            <div className="custom-container">
                <div className="grid grid-nogutter header-text">
                    <div className="col-12">
                        <div className='innerbnr-text-block'>
                            <h1>{props.header}</h1> 
                            <p>{props.subText}</p>
                            {props.visible && <PR.Button label={home.HOME0004} className="choose-plans-button" onClick={handleClick} />}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default SubHeader;