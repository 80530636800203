import * as PR from '../../prime-modules/index'

export const BundleListLoading = () => {

  return (
    <div className='skeleton-loading-section mt-5'>
      <div className='col-12 md:col-6 lg:col-3 medium-devices'>
        <div className="border-1 surface-border plan-box skeleton">
          <h3>
            <PR.Skeleton height="4rem" width='100%' borderRadius='0px'></PR.Skeleton>
          </h3>
          <div className="flex mb-3 align-items-center justify-content-between">
            <PR.Skeleton height="2.5rem" width="8rem" borderRadius='0px'></PR.Skeleton>
            <PR.Skeleton shape="circle" size="2.5rem" borderRadius='0px'></PR.Skeleton>
          </div>
          <div className='mb-3'>
            <span>
              <PR.Skeleton width='100%' borderRadius='0px'></PR.Skeleton>
            </span>
          </div>
          <div className='mb-3'>
            <span>
              <PR.Skeleton width='100%' borderRadius='0px'></PR.Skeleton>
            </span>
          </div>
          <div className='mb-3'>
            <span>
              <PR.Skeleton width='100%' borderRadius='0px'></PR.Skeleton>
            </span>
          </div>
          <div className='mb-4'>
            <span>
              <PR.Skeleton width='100%' borderRadius='0px'></PR.Skeleton>
            </span>
          </div>
          <PR.Skeleton width='100%' height='60px' borderRadius='0px'></PR.Skeleton>
        </div>
      </div>
      <div className='col-12 md:col-6 lg:col-3 medium-devices'>
        <div className="border-1 surface-border plan-box skeleton">
          <h3>
            <PR.Skeleton height="4rem" width='100%' borderRadius='0px'></PR.Skeleton>
          </h3>
          <div className="flex mb-3 align-items-center justify-content-between">
            <PR.Skeleton height="2.5rem" width="8rem" borderRadius='0px'></PR.Skeleton>
            <PR.Skeleton shape="circle" size="2.5rem" borderRadius='0px'></PR.Skeleton>
          </div>
          <div className='mb-3'>
            <span>
              <PR.Skeleton width='100%' borderRadius='0px'></PR.Skeleton>
            </span>
          </div>
          <div className='mb-3'>
            <span>
              <PR.Skeleton width='100%' borderRadius='0px'></PR.Skeleton>
            </span>
          </div>
          <div className='mb-3'>
            <span>
              <PR.Skeleton width='100%' borderRadius='0px'></PR.Skeleton>
            </span>
          </div>
          <div className='mb-4'>
            <span>
              <PR.Skeleton width='100%' borderRadius='0px'></PR.Skeleton>
            </span>
          </div>
          <PR.Skeleton width='100%' height='60px' borderRadius='0px'></PR.Skeleton>
        </div>
      </div>
      <div className='col-12 md:col-6 lg:col-3 medium-devices'>
        <div className="border-1 surface-border plan-box skeleton">
          <h3>
            <PR.Skeleton height="4rem" width='100%' borderRadius='0px'></PR.Skeleton>
          </h3>
          <div className="flex mb-3 align-items-center justify-content-between">
            <PR.Skeleton height="2.5rem" width="8rem" borderRadius='0px'></PR.Skeleton>
            <PR.Skeleton shape="circle" size="2.5rem" borderRadius='0px'></PR.Skeleton>
          </div>
          <div className='mb-3'>
            <span>
              <PR.Skeleton width='100%' borderRadius='0px'></PR.Skeleton>
            </span>
          </div>
          <div className='mb-3'>
            <span>
              <PR.Skeleton width='100%' borderRadius='0px'></PR.Skeleton>
            </span>
          </div>
          <div className='mb-3'>
            <span>
              <PR.Skeleton width='100%' borderRadius='0px'></PR.Skeleton>
            </span>
          </div>
          <div className='mb-4'>
            <span>
              <PR.Skeleton width='100%' borderRadius='0px'></PR.Skeleton>
            </span>
          </div>
          <PR.Skeleton width='100%' height='60px' borderRadius='0px'></PR.Skeleton>
        </div>
      </div>
      <div className='col-12 md:col-6 lg:col-3 medium-devices'>
        <div className="border-1 surface-border plan-box skeleton">
          <h3>
            <PR.Skeleton height="4rem" width='100%' borderRadius='0px'></PR.Skeleton>
          </h3>
          <div className="flex mb-3 align-items-center justify-content-between">
            <PR.Skeleton height="2.5rem" width="8rem" borderRadius='0px'></PR.Skeleton>
            <PR.Skeleton borderRadius='0px' size="2.5rem"></PR.Skeleton>
          </div>
          <div className='mb-3'>
            <span>
              <PR.Skeleton width='100%' borderRadius='0px'></PR.Skeleton>
            </span>
          </div>
          <div className='mb-3'>
            <span>
              <PR.Skeleton width='100%' borderRadius='0px'></PR.Skeleton>
            </span>
          </div>
          <div className='mb-3'>
            <span>
              <PR.Skeleton width='100%' borderRadius='0px'></PR.Skeleton>
            </span>
          </div>
          <div className='mb-4'>
            <span>
              <PR.Skeleton width='100%' borderRadius='0px'></PR.Skeleton>
            </span>
          </div>
          <PR.Skeleton width='100%' height='60px' borderRadius='0px'></PR.Skeleton>
        </div>
      </div>
    </div>
  )
}